import { Injectable } from '@angular/core';

import documentationItems from './documentation-items.json';

export interface AdditionalApiDoc {
  name: string;
  path: string;
}

export interface ExampleSpecs {
  prefix: string;
  exclude?: string[];
}

export interface DocItem {
  /** Id of the doc item. Used in the URL for linking to the doc. */
  id: string;
  /** Display name of the doc item. */
  name: string;
  /** Short summary of the doc item. */
  summary?: string;
  /** Package which contains the doc item. */
  packageName?: string;
  /** Specifications for which examples to be load. */
  exampleSpecs: ExampleSpecs;
  /** List of examples. */
  examples?: string[];
  /** Optional id of the API document file. */
  apiDocId?: string;
  /** Optional path to the overview file of this doc item. */
  overviewPath?: string;
  /** List of additional API docs. */
  additionalApiDocs?: AdditionalApiDoc[];
  /** Optional path to the readme file of this doc item. */
  readmePath?: string;
  /** Optional path to the screenshot file of this doc item. */
  screenshotPath?: string;
}

export interface DocSection {
  name: string;
  summary: string;
}

const COMPONENTS = 'components';
export const SECTIONS: { [key: string]: DocSection } = {
  [COMPONENTS]: {
    name: 'Components',
    summary: ''
  }
};

const DOCS: { [key: string]: DocItem[] } = {
  [COMPONENTS]: documentationItems
};

const ALL_COMPONENTS = processDocs('material', DOCS[COMPONENTS]);
const ALL_DOCS = [...ALL_COMPONENTS];

@Injectable()
export class DocumentationItems {

  getItems(section: string): DocItem[] {
    if (section === COMPONENTS) {
      return ALL_COMPONENTS;
    }
    return [];
  }

  getItemById(id: string, section: string): DocItem | undefined {
    const sectionLookup = section === 'cdk' ? 'cdk' : 'material';
    return ALL_DOCS.find(doc => doc.id === id && doc.packageName === sectionLookup);
  }
}

function processDocs(packageName: string, docs: DocItem[]): DocItem[] {
  for (const doc of docs) {
    doc.packageName = packageName;
  }

  return docs.sort((a, b) => a.name.localeCompare(b.name, 'en'));
}
