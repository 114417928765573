import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {RouterModule} from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import {CsrComponentDocsApp} from './csr-component-docs-app';
import {MATERIAL_DOCS_ROUTES} from './routes';

import { MarkdownModule } from 'ngx-markdown';

const prefersReducedMotion = typeof matchMedia === 'function' ?
  matchMedia('(prefers-reduced-motion)').matches : false;

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule.withConfig({disableAnimations: prefersReducedMotion}),
    RouterModule.forRoot(MATERIAL_DOCS_ROUTES, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'corrected'
    }),
    MarkdownModule.forRoot({ loader: HttpClient })
  ],
  declarations: [CsrComponentDocsApp],
  providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [CsrComponentDocsApp],
})
export class AppModule {}
